

body {
    background-color: #e8eff9;
}
.content {
    position: relative;
    margin-left: 250px;
    margin-top: 51px;
}

.ck .ck-powered-by {display: none;}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 10px solid;
    border-color: rgba(14, 13, 13, 0.15) rgba(158, 154, 154, 1) rgba(255, 255, 255, 1) rgba(255, 255, 255, 0.692);
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  pre {
  font-family: 'Times New Roman', Times, serif;
  background: #292929;
  color: #fafafa;
  font-size: 16px;
  padding: 1;
  padding: 10px;
  
  
}

.image-button:hover div.d-none {

  display: inline-block !important;
}